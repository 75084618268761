import { createQueryFunction } from './api'
import { createApp } from './main'
import { initSentry } from './utils/sentry'
import { type QueryFunction, hydrate } from '@tanstack/vue-query'

const { app, router, i18n, store, queryClient } = createApp()

initSentry(app, router)

const languagesBrowser = navigator.languages.join(',')
const language = i18n.global.availableLocales.find((lang) =>
  languagesBrowser.includes(lang)
)
i18n.global.locale = window.__LANGUAGE__ || language || 'ru'
console.log('[I18n] Language:', i18n.global.locale)

queryClient.setDefaultOptions({
  queries: {
    gcTime: 1000 * 30, // 30 seconds
    staleTime: 60_000, // sec
    queryFn: createQueryFunction({
      'Accept-Language': i18n.global.locale,
    }) as QueryFunction,
  },
})

hydrate(queryClient, window.__QUERY_CLIENT__ || {})
console.log('[QueryClient] Inited with state:', window.__QUERY_CLIENT__)
store.state.value = window.__INITIAL_STATE__ || {}
console.log('[Store] Inited with state:', window.__INITIAL_STATE__)

router.onError((error) => {
  console.error('[Router] Error:', error)
})
// wait until router is ready before mounting to ensure hydration match
router.isReady().then(() => {
  console.log('[Router] Ready')
  app.mount('#app', Boolean(window.__INITIAL_STATE__))
})
