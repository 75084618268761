import type { I18nOptions } from 'vue-i18n'

export const i18nConfig: I18nOptions = {
  legacy: false,
  // Новые правила можно поискать тут https://unicode-org.github.io/cldr-staging/charts/37/supplemental/language_plural_rules.html#sl
  // Или http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
  pluralRules: {
    /** Key - language to use the rule for, 'ru', in this case */
    /** Value - function
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     * */
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0
      }
      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1
      if (!teen && endsWithOne) {
        return 1
      }
      if (
        !teen &&
        choice % 10 >= 2 &&
        choice % 10 <= 4 &&
        (choice % 100 < 10 || choice % 100 >= 20)
      ) {
        return 2
      }
      return choicesLength < 4 ? 2 : 3
    },
  },
  messages: {},
  locale: 'ru',
  fallbackLocale: 'ru',
  availableLocales: ['ru', 'en'],
}
